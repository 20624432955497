import Icons from "./assets/images/icons";
import images from "./assets/images/images";

const { faZap } = Icons;
const { Image1, Image2, Image3 } = images;

const kits = [
  {
    id: 1,
    kit: "HIV-IFind Instant HIV AIDS Test Kit.",
    description:
      "HIV-IFind - Instant HIV AIDS Test Kit (Results in 20 minutes ; 99.8% accuracy).",
    image: Image1,
    icon: faZap,
    link: 'https://commerce.coinbase.com/checkout/1730622e-6962-4f3b-8fff-6519dd834293'
  },
  {
    id: 2,
    kit: "HIV-IFind Anti HIV AIDS Test Kit.",
    description:
      "HIV-IFind - Anti HIV AIDS Test Kit (Results in 30 minutes ; 98.7% accuracy).",
    image: Image2,
    icon: faZap,
    link: 'https://commerce.coinbase.com/checkout/f2a2ce81-813e-43d3-854c-81d4aa462bf7'
  },
  {
    id: 3,
    kit: "Pack of 2 - Instant and anti HIV test kit.",
    description:
      "Contains 2 test kits - one HIV-IFind Instant HIV Test Kit and one HIV-IFind Anti HIV Test Kit.",
    image: Image3,
    icon: faZap,
    link: 'https://commerce.coinbase.com/checkout/e17120ed-5577-4e5e-b150-7795a817e69f'
  }
];

export default kits;
