import {
  faUpload,
  faPhone,
  faInbox,
  faLock,
  faHome,
  faLocationArrow,
  faEnvelope,
  faBars,
  faBomb,
  faHashtag,
  faFloppyDisk,
  faFile,
  faZap,
  faPlug,
  faMobile
} from "@fortawesome/free-solid-svg-icons";

// Brand Icons installed using
// npm i --save @fortawesome/free-brands-svg-icons

import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Icons = {
  faUpload,
  faHome,
  faInbox,
  faLock,
  faLocationArrow,
  faEnvelope,
  faPhone,
  faFacebook,
  faTwitter,
  faInstagram,
  faBars,
  faBomb,
  faHashtag,
  faFloppyDisk,
  faFile,
  faZap,
  faPlug,
  faMobile
};

export default Icons;
