import React from "react";
import styled from "styled-components";
import Image from "../assets/images/about-bcg.jpg";

const About = () => {
  return (
    <Wrapper>
      <section className='section-center clearfix' id='about'>
        <article className='about-img'>
          <div className='about-picture-container'>
            <img src={Image} alt='kettle' className='about-picture' />
          </div>
        </article>
        <article className='about-info'>
          <div className='section-title'>
            <h3>HIV-IFind</h3>
          </div>
          <p className='about-text'>
            An HIV self-test (or rapid self-test) is an antibody test that can be used at home or in a private location. With an HIV self-test, you can get your test results within 20 minutes.
          </p>
          <p className='about-text'>
          Read the instructions included in the test kit before you start.
            <ol>
            <li>For an HIV self-test, you must swab your gums to collect an oral fluid sample and then test your sample.</li>
            <li>Results will be ready within 20 minutes.</li>
            <li>If you don’t follow the directions as described, the test may not work. There is a phone number included with the HIV self-test if you need help using the test.</li>
            </ol>
          </p>
          <a href='https://commerce.coinbase.com/checkout/1730622e-6962-4f3b-8fff-6519dd834293' className='btn'>
            Learn More
          </a>
        </article>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .about-img,
  .about-info {
    padding: 2rem 0;
  }

  .about-picture-container {
    background-color: var(--clr-primary);
    border: 0.5rem solid var(--clr-primary);
    max-width: 30rem;
    overflow: hidden;
  }

  .about-picture {
    transition: var(--transition);
  }

  .about-picture-container:hover .about-picture {
    opacity: 0.5;
    transform: scale(1.2);
  }

  .about-text {
    max-width: 26rem;
    color: var(--clr-grey-5);
  }

  @media screen and (min-width: 992px) {
    .about-img,
    .about-info {
      float: left;
      width: 50%;
    }
    .about-info {
      padding-left: 2rem;
    }
  }
`;

export default About;
