import Icons from "./assets/images/icons";

const { faHashtag } = Icons;

const orderprocedures = [
  {
    id: 1,
    orderprocedure: "Step 1",
    description:
      "Choose which product you want to buy and keep crypto ready to buy the product.",
    icon: faHashtag,
  },
  {
    id: 2,
    orderprocedure: "Step 2 (Optional)",
    description:
      "Make sure that you run your crypto via a crypto mixer to make the payment anonymous.",
    icon: faHashtag,
  },
  {
    id: 3,
    orderprocedure: "Step 3",
    description:
      "Create a new email, new nickname and choose a public address from where you can collect the parcel.",
    icon: faHashtag,
  },
  {
    id: 4,
    orderprocedure: "Step 4",
    description:
      "You will receive a code in the entered email, which will enable you to collect the parcel from the public address.",
    icon: faHashtag
  },
];

export default orderprocedures;
