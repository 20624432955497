const links = [
  {
    name: "Home",
    url: "#home",
  },
  {
    name: "about",
    url: "#about",
  },
  {
    name: "Order Procedure",
    url: "#orderprocedures",
  },
  {
    name: "kits",
    url: "#kits",
  }
];

export default links;
