import Home from "./pages/Home";
import Divider from "./components/Divider";
import OrderProcedures from "./components/OrderProcedures";
import About from "./pages/About";
import Kits from "./components/Kits";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Home />
      <Divider />
      <About />
      <Divider />
      <OrderProcedures />
      <Divider />
      <Kits />
      <Divider />
      <Footer />
    </div>
  );
}

export default App;