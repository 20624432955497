import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import orderprocedures from "../orderproceduresData";

const OrderProcedures = () => {
  return (
    <Wrapper>
      <section className='orderprocedures clearfix' id='orderprocedures'>
        {orderprocedures.map((item) => {
          const { id, orderprocedure, icon, description } = item;
          return (
            <article className='orderprocedure' key={id}>
              <span className='orderprocedure-icon'>
                <FontAwesomeIcon icon={icon} />
              </span>
              <h4 className='orderprocedure-title'>{orderprocedure}</h4>
              <p className='orderprocedure-text'>{description}</p>
            </article>
          );
        })}
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .orderprocedures {
    background: var(--clr-grey-10);
  }

  .orderprocedure {
    padding: 2.5rem 0;
    text-align: center;
    transition: var(--transition);
  }

  .orderprocedure-icon {
    font-size: 2.5rem;
    margin-bottom: 1.25rem;
    transition: var(--transition);
    display: inline-block;
    color: var(--clr-primary);
  }

  .orderprocedure-text {
    color: var(--clr-grey-5);
    max-width: 17rem;
    margin: 0 auto;
  }

  .orderprocedure:hover {
    background: var(--clr-white);
    box-shadow: 0 3px var(--clr-primary);
  }

  .orderprocedure:hover .orderprocedure-icon {
    transform: translateY(-5px);
  }

  @media screen and (min-width: 576px) {
    .orderprocedure {
      float: left;
      // 50% width will render a two column layout
      width: 50%;
    }
  }

  @media screen and (min-width: 1200px) {
    .orderprocedure {
      // 50% width will render a one row with each element taking 25%
      width: 25%;
    }
  }
`;

export default OrderProcedures;
